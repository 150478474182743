import { createMuiTheme } from '@material-ui/core/styles'
import * as session from '../util/session'

/**
 * Colors
 */

// Reds
export const RED = '#e02f64'
export const DARK_RED = '#c21d41'
export const PINK = '#ffdbe6'
export const RED_BORDER = 'rgba(245, 112, 128, 0.25)'

// Yellows
export const GOLD = '#9F6000'
export const POLLEN = '#FFF5CC'
export const YELLOW = '#F7E020'

// Other
export const ORANGE = '#FFA500'
export const GREEN = '#028F05'

/**
 * Blues
 */

export const OUTLINE = 'rgba(0, 123, 255, 0.25)'
export const SKY = '#e6f2ff'
export const SNOW = '#f0f2f5'
export const TRANSLUCENT_BLUE = 'rgba(65, 143, 222, 0.1)'

export const LIGHT_BLUE = 'rgb(80, 158, 208)'
export const BLUE = 'rgb(65, 143, 222)'
export const DARK_BLUE = 'rgb(50, 128, 207)'

/**
 * Neutrals
 */

export const BLACK = 'rgb(50, 50, 52)'
export const BLACK_ALPHA = (alpha: number): string =>
  `rgba(50, 50, 52, ${alpha})`
export const SHADE = 'rgba(0, 0, 0, 0.1)'
export const BORDER = 'rgba(0, 0, 0, 0.1)'
export const WHITE = '#FFFFFF'
export const WHITE_ALPHA = (alpha: number): string =>
  `rgba(255, 255, 255, ${alpha})`

export const GRAY_1 = 'rgb(50, 50, 52)'
export const GRAY_2 = 'rgb(106, 106, 109)'
export const GRAY_3 = 'rgb(162, 162, 166)'
export const GRAY_4 = 'rgb(218, 218, 223)'
export const GRAY_5 = 'rgb(248, 248, 254)'

export const PRIMARY = process.env.GATSBY_THEME_PRIMARY
export const SECONDARY = process.env.GATSBY_THEME_SECONDARY
export const BACKGROUND = process.env.GATSBY_THEME_BACKGROUND

export const SUBSCRIPTION_OUTLINE = process.env.GATSBY_SUBSCRIPTION_OUTLINE
export const SUBSCRIPTION_FILL = process.env.GATSBY_SUBSCRIPTION_FILL
export const SUBSCRIPTION_TEXT = process.env.GATSBY_SUBSCRIPTION_TEXT
export const SUBSCRIPTION_TEXT_IS_SELECTED =
  process.env.GATSBY_SUBSCRIPTION_TEXT_IS_SELECTED
export const SUBSCRIPTION_HEADER = process.env.GATSBY_SUBSCRIPTION_HEADER

export const DefaultColors = [
  '#51BBFE',
  '#8FF7A7',
  '#ABEBD2',
  '#214F4B',
  '#BD96EE',
  '#CFD6EA',
  '#4F5D2F',
  '#BFB6BB',
  '#A5D8FF',
  '#AFD0D6',
  '#C49799',
  '#36393B',
  '#BACDB0',
  '#729B79',
  '#475B63',
]

// LIVE APP SOLIN
export const GRAY_01 = 'rgba(0,0,0,0.70)'
export const GRAY_02 = '#A2A2A6'
export const BLUE_01 = '#418FDE'

export const SOLINTV_LIVE_BOTTOM_BANNER = 'rgba(65, 143, 222, 0.8)'
export const SOLINTV_LIVE_BACKGROUND = '#212121'

export const commonTheme = {
  primary: process.env.GATSBY_THEME_SECONDARY,
  headerBg: process.env.GATSBY_THEME_SECONDARY,
  pageBg: process.env.GATSBY_THEME_BACKGROUND,
  textColor: process.env.GATSBY_THEME_PRIMARY,
  pageBgPadding:
    process.env.GATSBY_THEME_TYPE === 'light' ? '#f5f6fa' : '#212121',
  liveBottomBanner:
    process.env.GATSBY_LIVE_BOTTOM_BANNER || SOLINTV_LIVE_BOTTOM_BANNER,
  liveBackground: process.env.GATSBY_LIVE_BACKGROUND || SOLINTV_LIVE_BACKGROUND,
}

const FONT_FAMILY =
  process.env.GATSBY_FONT_FAMILY !== undefined
    ? process.env.GATSBY_FONT_FAMILY
    : 'Roboto'

export const commonThemeMui = createMuiTheme({
  palette: {
    // @ts-ignore
    type: process.env.GATSBY_THEME_TYPE,
    primary: {
      main: process.env.GATSBY_THEME_SECONDARY,
    },
    secondary: { main: process.env.GATSBY_THEME_PRIMARY },
    text: { primary: process.env.GATSBY_THEME_PRIMARY },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontSize: 15.5,
  },
})

const commonConfig = {
  local: commonTheme,
  mui: commonThemeMui,
  logo: process.env.GATSBY_CLIENT_LOGO,
  livelogo: process.env.GATSBY_CLIENT_LOGO,
  livelogoWidth: process.env.GATSBY_CLIENT_LOGO_WIDTH || 90,
  logoHeight: process.env.GATSBY_CLIENT_LOGO_HEIGHT || 55,
}

type Themes =
  | 'solin'
  | 'bsp'
  | 'fuse'
  | 'gorilla'
  | 'board30'
  | 'mhrc'
  | 'shannon'
  | 'default'

// @ts-ignore
export const themeInSession: Themes = session.getClientName() || 'bsp'

export const themeColors = commonConfig.local
export const theme = commonConfig.mui
export const logo = commonConfig.logo
export const livelogo = commonConfig.livelogo
export const livelogoWidth = commonConfig.livelogoWidth
export const logoHeight = commonConfig.logoHeight

import s, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  WHITE,
  BORDER,
  BLACK_ALPHA,
  BLUE,
  DARK_BLUE,
} from '../../constants/colors'
import {
  HEADER_HEIGHT,
  maxWidth,
  PHONE,
  SHORT_ANIMATION_DURATION,
  BORDER_RADIUS_LG,
  M0,
  M1,
  M2,
  M3,
  M4,
} from '../../constants/measurements'

interface ICardProps {
  fullHeight?: boolean
  padding?: string
  hoverable?: boolean
  clickable?: boolean
  shaded?: boolean
  bordered?: boolean
  rounded?: boolean
  mb0?: boolean
  mb1?: boolean
  mb2?: boolean
  mb3?: boolean
  mb4?: boolean
}

export const Card = s.div<ICardProps>(
  ({
    padding,
    fullHeight,
    clickable,
    hoverable,
    bordered,
    rounded,
    shaded,
    mb0,
    mb1,
    mb2,
    mb3,
    mb4,
  }): FlattenSimpleInterpolation => css`
    background: ${WHITE};
    padding: ${padding || '1rem'};
    transition: all ${SHORT_ANIMATION_DURATION}ms ease;

    ${fullHeight ? `min-height: calc(100vh - ${HEADER_HEIGHT} + 4px);` : ''}
    ${clickable && 'cursor: pointer;'}
    ${rounded && `border-radius: ${BORDER_RADIUS_LG}; overflow: hidden;`}
    ${bordered && `border: 1px solid ${BORDER};`}
    margin-bottom: ${M0};
    ${mb0 && `margin-bottom: ${M0};`}
    ${mb1 && `margin-bottom: ${M1};`}
    ${mb2 && `margin-bottom: ${M2};`}
    ${mb3 && `margin-bottom: ${M3};`}
    ${mb4 && `margin-bottom: ${M4};`}
    ${
      shaded
        ? `box-shadow: 0 1px 18px ${BLACK_ALPHA(0.3)};`
        : `box-shadow: 0 0 0 ${BLACK_ALPHA(0)};`
    }


    ${hoverable &&
      `
      :hover,
      :focus,
      :active {
        ${
          shaded
            ? `box-shadow: 0 1px 4px ${BLACK_ALPHA(0.6)};}`
            : `box-shadow: 0 1px 4px ${BLACK_ALPHA(0.4)};`
        }
      }
    `}

    ${maxWidth(PHONE)} {
      margin-left: -1rem;
      margin-right: -1rem;
      width: calc(100% + 2rem);
    }
  `,
)

interface ISelectableCardProps {
  selected?: boolean
}

export const SelectableCard = s.div<ISelectableCardProps>`
  border-radius: 0;
  border-width: 0;
  border-left-width: 4px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  padding-left: ${M2};
  padding-top: ${M2};
  padding-bottom: ${M2};
  padding-right: 0;
  margin-bottom: ${M2};
  box-shadow: 0 0 0 transparent;
  transition: border-color ${SHORT_ANIMATION_DURATION}ms ease,
              box-shadow ${SHORT_ANIMATION_DURATION}ms ease;

  &:hover {
    border-color: ${BORDER};
  }

  ${(props): string =>
    props.selected
      ? `
    border-color: ${BLUE};

    &:hover {
      border-color: ${DARK_BLUE};
    }
  `
      : ''}
`

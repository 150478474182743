import React from 'react'
import styled, { keyframes, Keyframes } from 'styled-components'

import { themeColors } from '../../constants/colors'
import { Shade } from './Shade'
import {
  LONG_ANIMATION_DURATION,
  maxWidth,
  TABLET,
  PHONE,
  HEADER_HEIGHT,
} from '../../constants/measurements'

const Z_INDEX = 1300
const MARGIN = `calc(${HEADER_HEIGHT} + 5vh)`

export const slideIn = keyframes`
  0% {
    opacity: 0;
    margin-top: 100%;
    transform: scale(0.8);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    margin-top: ${MARGIN};
    transform: scale(1);
  }
`

export const slideOut = keyframes`
  0% {
    opacity: 1;
    margin-top: ${MARGIN};
    transform: scale(1);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    margin-top: 100%;
    transform: scale(0.8);
  }
`

interface IModalContentProps {
  show: boolean
}

const ModalContent = styled.div<IModalContentProps>`
  z-index: ${Z_INDEX};
  background: ${themeColors.pageBg};
  width: 50%;
  display: inline-block;
  margin-top: ${({ show }): string => (show ? `${MARGIN}` : '100vh')};
  margin-bottom: ${MARGIN};
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 1rem;
  text-align: left;
  position: relative;

  animation-name: ${({ show }): Keyframes => (show ? slideIn : slideOut)};
  animation-duration: ${LONG_ANIMATION_DURATION}ms;

  ${maxWidth(TABLET)} {
    width: 75%;
  }

  ${maxWidth(PHONE)} {
    width: calc(100% - 1rem);
  }
`

// Do not propagate events on the modal content to the modal background
// This would otherwise cause the modal to close on any click
const noop = (event: React.MouseEvent): void => event.stopPropagation()

export interface IModalProps {
  show: boolean
  setShow: (show: boolean) => void
  children?: React.ReactNode
}

interface IModalState {
  isNewlyMounted: boolean
}

export class Modal extends React.Component<IModalProps, IModalState> {
  /**
   * Fix current body position so it can't scroll
   */
  private static disableBodyScroll(): void {
    const scrollY = window.scrollY
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}px`
  }

  /**
   * Reset body styling to allow scrolling
   */
  private static enableBodyScroll(): void {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
  }

  private focusRef: React.RefObject<HTMLDivElement>

  constructor(props: IModalProps) {
    super(props)
    this.state = { isNewlyMounted: true }
    this.focusRef = React.createRef()
  }

  // Avoid animations showing on load
  public componentDidUpdate(prevProps: IModalProps): void {
    const { show } = this.props
    const { isNewlyMounted } = this.state

    // If this is the first time the modal is mounting, engage the animation
    if (isNewlyMounted && prevProps.show !== show) {
      this.makeNotNewlyMounted()
    }

    if (show && !prevProps.show) {
      // If we are showing the modal, focus on it
      const { current } = this.focusRef || {}
      if (current) {
        current.focus()
      }

      Modal.disableBodyScroll()
    } else if (!show && prevProps.show) {
      Modal.enableBodyScroll()
    }
  }

  public componentWillUnmount(): void {
    Modal.enableBodyScroll()
  }

  private makeNotNewlyMounted(): void {
    this.setState({ isNewlyMounted: false })
  }

  // Close the modal when the user presses the escape key
  private handleKeyPress(event: React.KeyboardEvent): void {
    const ESCAPE_KEY_CODE = 27
    const { show } = this.props

    if (
      (event.keyCode === ESCAPE_KEY_CODE ||
        event.key.toLowerCase() === 'escape') &&
      show
    ) {
      const { setShow } = this.props
      setShow(!show)
    }
  }

  public render(): React.ReactElement {
    const { show, setShow, children } = this.props
    const { isNewlyMounted } = this.state

    return (
      <Shade
        show={show}
        ref={this.focusRef}
        tabIndex={show ? 0 : -1}
        onClick={(): void => setShow(!show)}
        isNewlyMounted={isNewlyMounted}
        onKeyPress={(e: React.KeyboardEvent): void => this.handleKeyPress(e)}
        onKeyDown={(e: React.KeyboardEvent): void => this.handleKeyPress(e)}
        zIndex={Z_INDEX - 1}
      >
        <ModalContent onClick={noop} show={show}>
          {children}
        </ModalContent>
      </Shade>
    )
  }
}

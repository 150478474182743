import React, { useState } from 'react'
import s, { css } from 'styled-components'
import { Flex } from './Grid'
import { BLACK_ALPHA, BLUE, BLACK } from '../../constants/colors'
import { SHORT_ANIMATION_DURATION } from '../../constants/measurements'
import { BOLD_FONT_WEIGHT } from '../../constants/fonts'

const Tab = s.div<{ active: boolean }>(
  ({ active }) => css`
    flex: 1;
    border-width: 0 0 4px 0;
    border-color: ${active ? BLUE : BLACK_ALPHA(0.2)};
    border-style: solid;
    padding: 0.4rem 0;
    text-align: center;
    opacity: ${active ? 1 : 0.5};
    cursor: pointer;
    color: ${active ? BLUE : BLACK};
    font-weight: ${BOLD_FONT_WEIGHT};
    margin-bottom: 1rem;
    transition: all ${SHORT_ANIMATION_DURATION}ms ease;

    :hover,
    :focus {
      opacity: ${active ? 1 : 0.64};
    }
  `,
)

// const ContentWrapper = s.div<{ show: boolean; activeTab: number }>(
//   ({ show }) => css`
//     transition: opacity ${SHORT_ANIMATION_DURATION / 2}ms ease;
//     opacity: ${show ? 1 : 0};
//   `,
// )

// interface ITab {
//   title: string
//   content: React.ReactNode
// }

interface ITabsProps {
  tabs: string[]
  onChangeTab: (tab: number) => void
}

// interface ITabState {
//   activeTab: number
//   content: React.ReactNode
//   showContent: boolean
// }

export const Tabs = ({ tabs, onChangeTab }: ITabsProps): React.ReactElement => {
  const [activeTab, setActiveTab] = useState<number>(0)
  //   const [{ activeTab, content, showContent }, setState] = useState<ITabState>({
  //     activeTab: 0,
  //     // content: tabs[0].content,
  //     content: children[0],
  //     showContent: true,
  //   })
  //   const [isTransitioning, setIsTransitioning] = useState<boolean>(false)

  const handleClick = (idx: number): void => {
    if (idx === activeTab) return
    setActiveTab(idx)
    onChangeTab(idx)
  }

  //   useEffect(() => {
  //     const timeout = setTimeout(
  //       () =>
  //         setState({
  //           showContent: true,
  //           activeTab,
  //           //   content: tabs[activeTab].content,
  //           content: children[activeTab],
  //         }),
  //       SHORT_ANIMATION_DURATION / 2,
  //     )

  //     return function cleanup() {
  //       clearTimeout(timeout)
  //     }
  //   }, [activeTab])

  /* <ContentWrapper show={showContent} activeTab={activeTab}>
        {content}
      </ContentWrapper> */

  return (
    <Flex>
      {tabs.map((title, idx) => (
        <Tab
          key={`${title}-${idx}`}
          onClick={(): void => handleClick(idx)}
          active={activeTab === idx}
        >
          {title}
        </Tab>
      ))}
    </Flex>
  )
}

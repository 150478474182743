/**
 * Font families
 */

const BASE_FONT = `
    -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;`

export const BODY_FONT_FAMILY = `Roboto, ${BASE_FONT}`

/**
 * Font weights
 */

export const LIGHT_FONT_WEIGHT = 300
export const MEDIUM_FONT_WEIGHT = 400
export const BOLD_FONT_WEIGHT = 500

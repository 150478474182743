import React, { ReactNode } from 'react'
import s, { css, FlattenSimpleInterpolation } from 'styled-components'
import { Link } from 'gatsby'

import {
  BLUE,
  WHITE,
  TRANSLUCENT_BLUE,
  DARK_BLUE,
  OUTLINE,
  SECONDARY,
} from '../../constants/colors'
import {
  maxWidth,
  PHONE,
  SHORT_ANIMATION_DURATION,
  M0,
  M1,
  M2,
  M3,
  M4,
  DESKTOP,
  minWidth,
} from '../../constants/measurements'
import { MEDIUM_FONT_WEIGHT } from '../../constants/fonts'
import { notSelectableStyles } from '../../constants/misc'
import { LoadingSpinner } from './LoadingSpinner'
import { getFontSize } from './Typography'

export enum EBtnKind {
  Primary,
  Secondary,
  Danger,
}

export enum EBtnSize {
  LG,
  MD,
  SM,
}

export interface IBtnProps {
  fullWidth?: boolean
  fullWidthOnMobile?: boolean
  disabled?: boolean
  bordered?: boolean
  size?: EBtnSize
  kind?: EBtnKind
  children?: ReactNode
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  style?: React.CSSProperties
  mb0?: boolean
  mb1?: boolean
  mb2?: boolean
  mb3?: boolean
  mb4?: boolean
}

const getKindStyles = (kind?: EBtnKind, disabled?: boolean): string => {
  if (kind === EBtnKind.Secondary) {
    return `
      background: transparent;
      color: ${BLUE} !important;
      border-color: ${TRANSLUCENT_BLUE};

      &:hover,
      &:active,
      &:focus {
        background: ${disabled ? 'transparent' : TRANSLUCENT_BLUE};
      }`
  }

  if (kind === EBtnKind.Danger) {
    return `
      background: ${SECONDARY};
      color: ${WHITE} !important;
      border-color: ${SECONDARY};

      &:hover,
      &:active,
      &:focus {
        opacity: 0.8;
      }`
  }

  return `
    background: ${BLUE};
    color: ${WHITE} !important;
    border-color: ${DARK_BLUE};

    &:hover,
    &:active,
    &:focus {
      background: ${disabled ? BLUE : DARK_BLUE};
    }`
}

const getBorderRadius = (size?: EBtnSize): string =>
  size === EBtnSize.LG ? '2rem' : size === EBtnSize.SM ? '1.28rem' : '1.6rem'

const getPadding = (size?: EBtnSize): string =>
  size === EBtnSize.LG
    ? '0.75rem 1.4rem'
    : size === EBtnSize.SM
    ? '0.25rem 0.8rem'
    : '0.5rem 1.2rem'

const Btn = ({
  size,
  mb0,
  mb1,
  mb2,
  mb3,
  mb4,
  bordered,
  disabled,
  fullWidth,
  fullWidthOnMobile,
  kind,
}: IBtnProps): FlattenSimpleInterpolation => css`
  font-weight: ${MEDIUM_FONT_WEIGHT};
  border: none;
  border-radius: ${getBorderRadius(size)};
  padding: ${getPadding(size)};
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transition: all ${SHORT_ANIMATION_DURATION}ms ease;

  margin-bottom: 1rem;
  ${mb0 && `margin-bottom: ${M0};`}
  ${mb1 && `margin-bottom: ${M1};`}
  ${mb2 && `margin-bottom: ${M2};`}
  ${mb3 && `margin-bottom: ${M3};`}
  ${mb4 && `margin-bottom: ${M4};`}
  border-width: ${bordered ? '2px' : '0'};
  border-style: solid;

  font-size: ${getFontSize({
    lg: size === EBtnSize.LG,
    sm: size === EBtnSize.SM,
    normal: 1.2,
  })};

  ${minWidth(DESKTOP)} {
    font-size: ${getFontSize({
      lg: size === EBtnSize.LG,
      sm: size === EBtnSize.SM,
      normal: 1.2,
    })};
  }

  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${OUTLINE};
  }

  ${maxWidth(PHONE)} {
    padding: 0.5rem 0.75rem;
    ${fullWidthOnMobile && 'width: 100%; text-align: center;'}
  }

  ${disabled && `opacity: 0.5; cursor: not-allowed; ${notSelectableStyles}`}
  ${fullWidth && 'width: 100%; text-align: center;'}

  ${getKindStyles(kind, disabled)}
`

export const BtnBtn = s.button<IBtnProps>(Btn)

export const BtnAnchor = s.a<IBtnProps>(Btn)

interface ILinkProps {
  to: string
  style?: React.CSSProperties
}

type IBtnLinkProps = IBtnProps & ILinkProps

const BtnLinkLink = s(Link)`
  &:focus {
    outline: 0;
    box-shadow: none;

    button {
      box-shadow: 0 0 0 0.2rem ${OUTLINE};
    }
  }
`

// Structure the link differently so that styling props are not passed
// to the DOM where they might cause rendering errors
export const BtnLink = ({
  to,
  fullWidth,
  ...rest
}: IBtnLinkProps): React.ReactElement => (
  <BtnLinkLink to={to}>
    <BtnBtn fullWidth={fullWidth} {...rest} tabIndex={-1} />
  </BtnLinkLink>
)

interface IInputProps {
  type?: string
  value?: string | number
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

export const BtnLoadingSpinner = ({}): React.ReactElement => (
  <LoadingSpinner
    size="1.2rem"
    white
    delay={SHORT_ANIMATION_DURATION / 2}
    thickness="3px"
    style={{
      cursor: 'not-allowed',
      position: 'absolute',
      zIndex: 1,
      width: 'auto',
      right: '1rem',
      marginTop: '-1.825rem',
      padding: '0 0.6rem 0 0',
    }}
  />
)

type IBtnInputProps = IBtnProps & IInputProps

const BtnInputTag = s.input<IBtnInputProps>(Btn)

/**
 * Button to be used in form (primarily for submitting said form).
 *
 * @param props.pending  - if a loading spinner should be shown
 * @param props.style    - to apply to the button
 * @param props.disabled - if the button should be unclickable and slightly
 *                      transparent
 * @param ...others
 */
export const BtnInput = ({
  pending,
  style,
  ...rest
}: IBtnInputProps & { pending?: boolean }): React.ReactElement => (
  <div
    style={{
      position: 'relative',
      width: (style && style.width) || '100%',
      marginBottom: (style && style.marginBottom) || '1rem',
    }}
  >
    <BtnInputTag
      style={{ ...style, marginBottom: 0, width: '100%' }}
      {...rest}
    />
    {pending && <BtnLoadingSpinner />}
  </div>
)

interface IButtonsProps {
  center?: boolean
}

/**
 * A wrapper around a set of buttons such that all buttons which are children
 * render in line and with a margin between them.
 *
 * @param props.center - if the buttons should be centered, default is left aligned
 */
export const Buttons = s.div<IButtonsProps>`
  width: 100%;
  margin-bottom: 1rem;

  ${(props): string => (props.center ? 'text-align: center;' : '')}

  a,
  button,
  input {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`

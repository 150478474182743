export const HEADER_HEIGHT = '54px'
export const HEADER_Z_INDEX = 1000

export const BORDER_RADIUS = '3px'
export const BORDER_RADIUS_LG = '6px'

export const SHORT_ANIMATION_DURATION = 200
export const LONG_ANIMATION_DURATION = 400

type TScreenWidth = string

export const LARGE_DESKTOP_WIDTH = 1650
export const DESKTOP_WIDTH = 1248
export const TABLET_WIDTH = 1025
export const PHONE_WIDTH = 850

export const LARGE_DESKTOP: TScreenWidth = `${LARGE_DESKTOP_WIDTH}px`
export const DESKTOP: TScreenWidth = `${DESKTOP_WIDTH}px`
export const TABLET: TScreenWidth = `${TABLET_WIDTH}px`
export const PHONE: TScreenWidth = `${PHONE_WIDTH}px`

type TMediaQuery = string

export const minWidth = (w: TScreenWidth): TMediaQuery =>
  `@media screen and (min-width: ${w})`
export const maxWidth = (w: TScreenWidth): TMediaQuery =>
  `@media screen and (max-width: ${w})`

// Margin sizes
export const M0 = '0'
export const M1 = '0.4rem'
export const M2 = '0.8rem'
export const M3 = '1.2rem'
export const M4 = '1.6rem'

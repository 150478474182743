/**
 * Swipable carousel with toggles for circles and arrows
 *
 * Inpsired by https://react-swipeable-views.com/demos/demos/#circular
 */

import React, { useState } from 'react'
import s from 'styled-components'
import SwipeableViews from 'react-swipeable-views'
import { virtualize } from 'react-swipeable-views-utils'

import {
  SHORT_ANIMATION_DURATION,
  M2,
  minWidth,
  TABLET,
  M1,
  maxWidth,
  PHONE,
} from '../../constants/measurements'
import { BLUE, BORDER, BLACK_ALPHA, WHITE } from '../../constants/colors'

const VirtualizeSwipeableViews = virtualize(SwipeableViews)

const Circles = s.div`
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all ${SHORT_ANIMATION_DURATION}ms ease;
`

interface ICircleProps {
  active?: boolean
}

const Circle = s.div<ICircleProps>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-left: ${M2};
  margin-right: ${M2};
  cursor: pointer;
  transition: all ${SHORT_ANIMATION_DURATION}ms ease;
  background: ${(props): string => (props.active ? BLUE : BORDER)};

  &:hover {
    background: ${(props): string => (props.active ? BLUE : BLACK_ALPHA(0.2))};
  }
`

const Content = s.div`
  padding: ${M2} 2rem;
  height: auto;
  width: 100%;

  ${minWidth(TABLET)} {
    padding: ${M2} calc(1.6rem + 5vw);
  }
`

const sharedArrowStyles = `
  background: ${WHITE};
  box-shadow: 0 2px 4px ${BLACK_ALPHA(0.4)};
  font-size: 2rem;
  padding: ${M1};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-100%);
  cursor: pointer;
  transition: all ${SHORT_ANIMATION_DURATION}ms ease;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.4rem;
  width: 2.4rem;
  color: ${BLACK_ALPHA(0.8)};
  user-select: none;

  ${maxWidth(PHONE)} {
    height: 2rem;
    width: 2rem;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${BLACK_ALPHA(1)};
    box-shadow: 0 1px 3px ${BLACK_ALPHA(0.6)};
  }
`

const ArrowLeft = s.span`
  ${sharedArrowStyles}
  left: 0;

  ${maxWidth(TABLET)} {
    left: -${M2};
  }

  ${maxWidth(PHONE)} {
    left: -${M1};
  }
`

const ArrowRight = s.span`
  ${sharedArrowStyles}
  right: 0;

  ${maxWidth(TABLET)} {
    right: -${M2};
  }

  ${maxWidth(PHONE)} {
    right: -${M1};
  }
`

// const swipeConfidenceThreshold = 10000
// const swipePower = (offset: number, velocity: number): number =>
//   Math.abs(offset) * velocity

// const variants: Variants = {
//   enter: (direction: number): TargetAndTransition => ({
//     x: direction > 0 ? 1000 : -1000,
//     opacity: 0,
//   }),
//   center: {
//     zIndex: 1,
//     x: 0,
//     opacity: 1,
//   },
//   exit: (direction: number): TargetAndTransition => ({
//     zIndex: 0,
//     x: direction < 0 ? 1000 : -1000,
//     opacity: 0,
//   }),
// }

enum EDirection {
  Left = -1,
  Right = 1,
}

interface ICarouselProps {
  data: Array<any>
  RenderComponent: (props: any) => React.ReactElement
  WrapperComponent: ({
    children,
  }: {
    children: React.ReactNode | React.ReactNodeArray
  }) => React.ReactElement
  circles?: boolean
}

export const Carousel = ({
  data,
  circles,
  RenderComponent,
  WrapperComponent,
}: ICarouselProps): React.ReactElement => {
  const slideRenderer = ({
    index,
    key,
  }: {
    index: number
    key: string | number
  }): React.ReactElement => {
    const idx = ((index % data.length) + data.length) % data.length
    return (
      <Content key={key}>
        <RenderComponent {...data[idx]} />
      </Content>
    )
  }

  const [activeIdx, setActiveIdx] = useState<number>(0)
  const dataIdx = ((activeIdx % data.length) + data.length) % data.length
  // const activeData = data[dataIdx]

  const go = (newDirection: EDirection): void =>
    setActiveIdx(activeIdx + newDirection)
  const goLeft = (): void => go(EDirection.Left)
  const goRight = (): void => go(EDirection.Right)

  // TODO container styles
  return (
    <>
      <WrapperComponent>
        <ArrowLeft onClick={(): void => goLeft()}>&#9666;</ArrowLeft>
        <ArrowRight onClick={(): void => goRight()}>&#9656;</ArrowRight>
        <VirtualizeSwipeableViews
          animateHeight
          slideRenderer={slideRenderer}
          index={activeIdx}
          onChangeIndex={(idx: number): void => {
            setActiveIdx(idx)
          }}
        />
      </WrapperComponent>
      {circles && (
        <Circles>
          {data.map((_, idx) => (
            <Circle
              key={idx}
              active={dataIdx === idx}
              onClick={(): void => {
                setActiveIdx(idx)
              }}
            />
          ))}
        </Circles>
      )}
    </>
  )
}

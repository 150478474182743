import React from 'react'
import s, { css } from 'styled-components'

interface ICircularImageProps {
  width: string
  style?: React.CSSProperties
}

export const CircularImage = s.img<ICircularImageProps>(
  ({ width }) => css`
    border-radius: 50%;
    width: ${width};
    height: ${width};
    object-fit: contain;
  `,
)

interface IBackgroundImageProps {
  src: string
}

export const BackgroundImage = s.div<IBackgroundImageProps>(
  ({ src }) => css`
    background-image: url("${src}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `,
)

import React, { useState, useEffect } from 'react'
import s from 'styled-components'

import { Text } from './Typography'
import { PINK, RED, BLUE, SKY, POLLEN, GOLD } from '../../constants/colors'
import { BORDER_RADIUS } from '../../constants/measurements'
import { getQueryParams } from '../forms/util/helpers'
import { AlertTriangleIcon, InfoIcon, AlertCircleIcon } from './Icons'

interface IMessageProps {
  message?: string
  bordered?: boolean
  showIcon?: boolean
  style?: any
}

enum T {
  ERROR,
  INFO,
  WARN,
}

interface IColorMap {
  bg: string
  color: string
}

const typeColorMap: { [key in T]: IColorMap } = {
  [T.ERROR]: {
    bg: PINK,
    color: RED,
  },
  [T.WARN]: {
    bg: POLLEN,
    color: GOLD,
  },
  [T.INFO]: {
    bg: SKY,
    color: BLUE,
  },
}

interface IWrapperProps {
  type: T
  bordered?: boolean
}

const getTypeStyles = (type: T): string => {
  const mapping = typeColorMap[type]
  if (!mapping) return ''
  const { bg, color } = mapping
  return `background: ${bg}; color: ${color}; border-color: ${color};`
}

const Wrapper = s.div<IWrapperProps>`
  padding: 0.75rem;
  border-radius: ${BORDER_RADIUS};
  margin-bottom: 1rem;
  border-width: ${({ bordered }): string => (bordered ? '2px' : '0')};
  border-style: solid;
  display: flex;

  p {
    margin-bottom: 0;
  }

  ${({ type }): string => getTypeStyles(type)}
`

const IconWrapper = s.div`
  margin-right: 0.5rem;
  width: auto;

  // Do not let icon interfere with height of error message
  height: 0;
  overflow-y: visible;
`

const TextWrapper = s.div`
  flex: 1;
`

// eslint-disable-next-line react/display-name
const messageGenerator = (type: T) => ({
  message,
  showIcon = true,
  ...props
}: IMessageProps): React.ReactElement | null => {
  if (!message) return null
  const color = typeColorMap[type].color
  return (
    <Wrapper type={type} {...props}>
      {showIcon && (
        <IconWrapper color={color}>
          {type === T.ERROR ? (
            <AlertTriangleIcon />
          ) : type === T.WARN ? (
            <AlertCircleIcon />
          ) : (
            <InfoIcon />
          )}
        </IconWrapper>
      )}
      <TextWrapper>
        <Text sm>{message}</Text>
      </TextWrapper>
    </Wrapper>
  )
}

export const ErrorMessage = messageGenerator(T.ERROR)
export const WarningMessage = messageGenerator(T.WARN)
export const InfoMessage = messageGenerator(T.INFO)

const MESSAGE = 'message'

/**
 * Look for the `message` query param in the URL and render it to the user as
 * an `<InfoMessage>`
 *
 * Can be imported anywhere
 */
export const QueryParamMessage = (): React.ReactElement => {
  const [message, setMessage] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (message) return
    const queryParams = getQueryParams()
    if (queryParams) {
      const newMessage = queryParams.get(MESSAGE)
      if (!newMessage) return
      setMessage(newMessage)
    }
  }, [message])

  return <InfoMessage message={message} />
}

interface IUnusuallyLongRequestMessage {
  pending?: boolean
  message?: string
  delay?: number
}

/**
 * Show the user a message if some operation is taking longer than expected to
 * complete
 *
 * NOTE the message only displays if the message prop is non-empty and defined
 *
 * @param props.pending - `true` by default, do not show the message if not pending
 * @param props.message - shown to user when delay completes
 * @param props.delay   - time to wait in ms before showing message
 */
export const UnusuallyLongRequestMessage = ({
  pending = true,
  message = 'Request taking longer than usual...',
  delay = 2500,
}: IUnusuallyLongRequestMessage): React.ReactElement | null => {
  const [show, setShow] = useState<boolean>(false)
  useEffect(() => {
    if (!pending) return
    const timeout = setTimeout(() => setShow(true), delay)
    return (): void => clearInterval(timeout)
  }, [delay, pending])

  /**
   * Do not show the error message if:
   * 1. the delay has not yet passed or
   * 2. the request / operation is no longer pending
   */
  if (!show || pending === false) return null
  return <WarningMessage message={message} />
}

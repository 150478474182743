import React from 'react'
import s, { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  M0,
  M1,
  M2,
  M3,
  M4,
  DESKTOP,
  minWidth,
} from '../../constants/measurements'
import { BORDER } from '../../constants/colors'
import {
  BOLD_FONT_WEIGHT,
  LIGHT_FONT_WEIGHT,
  MEDIUM_FONT_WEIGHT,
} from '../../constants/fonts'

export interface ITextProps {
  center?: boolean
  color?: string
  sm?: boolean
  lg?: boolean
  xs?: boolean
  mb0?: boolean
  mb1?: boolean
  mb2?: boolean
  mb3?: boolean
  mb4?: boolean
  fontSize?: string
  fontSizeDesktop?: string
  children: string | React.ReactNode | React.ReactNodeArray | string[]
  style?: React.CSSProperties
  light?: boolean
  lighter?: boolean
  lightest?: boolean
  bold?: boolean
  medium?: boolean
}

/**
 * Given a mix of props passed to text and the `normal` (default text size),
 * return the text size in `em`
 */
export const getFontSize = ({
  xs,
  sm,
  lg,
  normal,
}: Partial<ITextProps> & { normal: number }): string => {
  const sizeNum = xs
    ? normal / (1.2 * 1.2)
    : sm
    ? normal / 1.2
    : lg
    ? normal * 1.2
    : normal
  return `${sizeNum}em`
}

export const Text = s.p<ITextProps>(
  ({
    color,
    center,
    xs,
    sm,
    lg,
    mb0,
    mb1,
    mb2,
    mb3,
    mb4,
    fontSize,
    fontSizeDesktop,
    light,
    lighter,
    lightest,
    bold,
    medium,
  }): FlattenSimpleInterpolation => css`
    line-height: 1.45;
    color: ${color || 'inherit'};
    text-align: ${center ? 'center' : 'left'};
    font-weight: ${
      bold ? BOLD_FONT_WEIGHT : medium ? MEDIUM_FONT_WEIGHT : LIGHT_FONT_WEIGHT
    };
    font-size: ${fontSize || getFontSize({ xs, sm, lg, normal: 1.2 })};
    ${mb0 && `margin-bottom: ${M0};`}
    ${mb1 && `margin-bottom: ${M1};`}
    ${mb2 && `margin-bottom: ${M2};`}
    ${mb3 && `margin-bottom: ${M3};`}
    ${mb4 && `margin-bottom: ${M4};`}
    ${light && 'opacity: 0.8;'}
    ${lighter && 'opacity: 0.64;'}
    ${lightest && 'opacity: 0.512;'}

    ${minWidth(DESKTOP)} {
      font-size: ${fontSizeDesktop || getFontSize({ xs, sm, lg, normal: 1.25 })}
    }
  `,
)

export const P = Text

export const H1 = ({
  xs,
  sm,
  lg,
  children,
  ...rest
}: ITextProps): React.ReactElement => (
  <P
    as="h1"
    fontSize={getFontSize({ xs, sm, lg, normal: 2 })}
    fontSizeDesktop={getFontSize({ xs, sm, lg, normal: 2.4 })}
    bold
    {...rest}
  >
    {children}
  </P>
)
export const H2 = ({
  xs,
  sm,
  lg,
  children,
  ...rest
}: ITextProps): React.ReactElement => (
  <P
    as="h2"
    fontSize={getFontSize({ xs, sm, lg, normal: 1.8 })}
    fontSizeDesktop={getFontSize({ xs, sm, lg, normal: 2.2 })}
    bold
    {...rest}
  >
    {children}
  </P>
)
export const H3 = ({
  xs,
  sm,
  lg,
  children,
  ...rest
}: ITextProps): React.ReactElement => (
  <P
    as="h3"
    fontSize={getFontSize({ xs, sm, lg, normal: 1.6 })}
    fontSizeDesktop={getFontSize({ xs, sm, lg, normal: 2 })}
    bold
    {...rest}
  >
    {children}
  </P>
)
export const H4 = ({
  xs,
  sm,
  lg,
  children,
  ...rest
}: ITextProps): React.ReactElement => (
  <P
    as="h4"
    fontSize={getFontSize({ xs, sm, lg, normal: 1.4 })}
    fontSizeDesktop={getFontSize({ xs, sm, lg, normal: 1.8 })}
    medium
    {...rest}
  >
    {children}
  </P>
)
export const H5 = ({ children, ...rest }: ITextProps): React.ReactElement => (
  <P medium as="h5" {...rest}>
    {children}
  </P>
)
export const H6 = ({ children, ...rest }: ITextProps): React.ReactElement => (
  <P medium as="h6" {...rest}>
    {children}
  </P>
)

export const HR = s.hr`
  background: ${BORDER};
  margin-top: ${M4};
  margin-bottom: ${M4};
  display: block;
  width: 100%;
`

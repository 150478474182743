import * as React from 'react'
import { IIconProps } from './types'
import { Svg } from './Misc'

export const Lock = (props: IIconProps): React.ReactElement => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <rect x={3} y={11} width={18} height={11} rx={2} ry={2} />
    <path d="M7 11V7a5 5 0 0110 0v4" />
  </Svg>
)

import * as React from 'react'
import { IIconProps } from './types'
import { Svg } from './Misc'

export const ChevronLeft56 = (props: IIconProps): React.ReactElement => (
  <Svg
    width={56}
    height={56}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-chevron-left"
    {...props}
  >
    <path d="M15 18l-6-6 6-6" />
  </Svg>
)

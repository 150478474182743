import React from 'react'
import s from 'styled-components'
import BackgroundImage, { IFluidObject } from 'gatsby-background-image'

import { Spacer, Container } from './Grid'
import {
  maxWidth,
  minWidth,
  PHONE,
  DESKTOP,
} from '../../constants/measurements'
import { BLACK } from '../../constants/colors'

// @ts-ignore
const HeroContainer = s(Container)`
  padding-top: 10vh;
  padding-bottom: 10vh;

  ${maxWidth(PHONE)} {
    padding-top: calc(1rem + 5vh);
    padding-bottom: calc(1rem + 5vh);
  }

  ${minWidth(DESKTOP)} {
    padding-top: 12.5vh;
    padding-bottom: 12.5vh;
  }
`

const Wrapper = s.div`
  background: rgba(0, 0, 0, 0.325);
`

interface IHeroProps {
  imageData: IFluidObject | (IFluidObject | string)[]
  children: React.ReactNode
}

export const Hero = ({
  imageData,
  children,
}: IHeroProps): React.ReactElement => (
  <BackgroundImage Tag="section" fluid={imageData} backgroundColor={BLACK}>
    <Wrapper>
      <Spacer />
      <HeroContainer>{children}</HeroContainer>
      <Spacer />
    </Wrapper>
  </BackgroundImage>
)

import * as React from 'react'
import { IIconProps } from './types'
import { Svg } from './Misc'

export const CircleCheck = (props: IIconProps): React.ReactElement => (
  <Svg
    width={40}
    height={40}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-check-circle"
    {...props}
  >
    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
    <path d="M22 4L12 14.01l-3-3" />
  </Svg>
)

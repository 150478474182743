import * as React from 'react'
import { IIconProps } from './types'
import { Svg } from './Misc'

export const Play = (props: IIconProps): React.ReactElement => (
  <Svg
    width={36}
    height={36}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="prefix__feather prefix__feather-play"
    {...props}
  >
    <path d="M5 3l14 9-14 9V3z" />
  </Svg>
)

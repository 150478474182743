import s from 'styled-components'
import { BORDER } from '../../constants/colors'

export const Line = s.hr`
  background: ${BORDER};
  height: 2px;
  width: 100%;
  margin: 1rem 0;
`

export const ThinLine = s(Line)`
  height: 1px;
`

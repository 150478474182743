import React from 'react'
import { Svg } from './Misc'

import { IIconProps } from './types'

export const X = (props: IIconProps) => (
  <Svg
    width="24"
    height="24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    className="feather feather-x"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M18 6L6 18"></path>
    <path d="M6 6L18 18"></path>
  </Svg>
)
